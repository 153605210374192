import React from 'react';
import Link from 'gatsby-link';

import { BurgerIcon } from '../Icons/BurgerIcon';
import { CrossIcon } from '../Icons/CrossIcon';
import { IconBrand } from '../Icon/Icon';

export class Nav extends React.Component {
	constructor(props) {
		super(props);
		this.state = { isOpened: false };
	}

	handleClose = () => this.setState({ isOpened: false });
	handleOpen = () => this.setState({ isOpened: true });

	renderLinks = (links, onClick) => {
		return links.map(link => (
			<Link key={link.to} to={link.to} title={link.name} onClick={onClick}>
				{link.name}
			</Link>
		));
	};

	render() {
		const links = [
			{ name: 'Home', to: '/' },
			{ name: 'Experience', to: '/experience' },
			{ name: 'Recensioni', to: '/recensioni' },
			{ name: 'Contatti', to: '/contatti' }
		];
		return (
			<div className="nav">
				<div className="grid">
					<a
						className="mobile-menu -open"
						onClick={this.handleOpen}
						role="button"
						tabIndex={0}>
						<BurgerIcon />
					</a>

					<nav
						id="mobile-nav"
						className={`main-nav -mobile ${
							this.state.isOpened ? 'is-opened' : null
						}`}>
						<a
							className="mobile-menu -close"
							onClick={this.handleClose}
							role="button"
							tabIndex={0}>
							<CrossIcon />
						</a>
						{this.renderLinks(links, this.handleClose)}
					</nav>
					<nav className="main-nav -desktop col md-10 md-push-1">
						<Link to="/" className="logo">
							<img src="/images/gp_logo_light.png" />
						</Link>

						{this.renderLinks(links)}
					</nav>
				</div>
			</div>
		);
	}
}
