import React from 'react';
import Icon from '../Icon/Icon';

export function CrossIcon() {
	return (
		<span className="black-70 f4">
			<Icon name="times" />
		</span>
	);
}
