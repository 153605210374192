import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import { Nav } from '../components/Nav/Nav';
import { Footer } from '../components/Footer/Footer';
import CookieConsent from "react-cookie-consent";
import './index.scss';

export default class Layout extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		// const { location } = this.props;
		// ReactGA.initialize('UA-117099658-1');
		// ReactGA.pageview(location.pathname + location.search);
	}

	componentDidUpdate() {
		// const { location } = this.props;
		// ReactGA.pageview(location.pathname + location.search);
	}

	render() {
		return (
			<div>
				<Nav />
				{this.props.children}
				<Footer />

				<CookieConsent
					location="bottom"
					buttonText="Mi sembra ottimo"
					cookieName="privacyCookie"
					style={{ background: "#fafafa", fontSize: ".85rem", color: "#303030", alignItems: 'center', lineHeight: 1.5, margin: 0, padding: '1rem' }}
					buttonStyle={{ color: "#fff", background: '#c62828', letterSpacing: 0, fontSize: ".85rem", fontWeight: 600, borderRadius: '30px', padding: '0.5rem 1.5rem', margin: 0 }}
					expires={150}
				>
					<b>Utilizziamo i cookie per essere sicuri che tu possa avere la
						migliore esperienza sul nostro sito.</b><br/>Mi affido a Giovedì Pizza nella buona e nella cattiva sorte,
					in salute ed in malattia finché Venerdì non ci separi fino al prossimo Giovedì.<br/>
					<a href="/privacy"><b>Maggiori informazioni</b></a>
				</CookieConsent>

			</div>
		);
	}
}

Layout.propTypes = {
	children: PropTypes.func
};
