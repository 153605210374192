import React, {Component} from 'react';

export default class Icon extends Component {
    render() {
        const { name, className } = this.props;
        const icon = 'fal fa-fw '+'fa-'+name;

        return (
            <span className={className}>
                <i className={icon}/>
            </span>
        );
    }
}

export class IconBrand extends Component {
    render() {
        const { name, className } = this.props;
        const icon = 'fab fa-fw '+'fa-'+name;

        return (
            <span className={className}>
                <i className={icon}/>
            </span>
        );
    }
}