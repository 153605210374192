import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Copyright from '../Copyright/Copyright';

export function Footer() {
	return (
		<footer>
			<Copyright />
		</footer>
	);
}
