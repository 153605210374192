import React from 'react';
import Icon from '../Icon/Icon';

export function BurgerIcon() {
	return (
		<span className="black-70 f4">
			<Icon name="bars" />
		</span>
	);
}
