import React, { Component } from 'react';

export default class Copyright extends Component {
	render() {
		return (
			<div className="cf ph2-ns w-100 w-90-ns mt3 center tc copyright">
				Copyright 2018 All Rights Reserved <b>Giovedì Pizza</b> | P.I. Ce
				l'avremo | <a href="/privacy">Privacy &amp; Cookies Policy</a>
			</div>
		);
	}
}
